import React, { useState } from 'react';
import Logo from "./assets/images/cms-icon.png"
import LogoGiga from "./assets/images/logoGiga.png"
import Navbar from "react-bootstrap/Navbar"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav"
import TitleAnim from './components/TitleAnim';

// PAGES
import Home from './pages/Home';
import Internet from './pages/Internet';
import CardSale from './components/HomeComponents/CardSale';
import Tv from './pages/Tv';
import Phone from './pages/Phone';
import Private from './pages/Private';
import About from './pages/About';
import Videos from './pages/Videos';
import Settings from './pages/Settings';

import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import "font-awesome/css/font-awesome.min.css"
import styled from "styled-components"

import './App.css';
import CardOffer from './components/HomeComponents/CardOffer';
import Telefone from './pages/Phone';
import RuralInternet from './pages/RuralInternet';
import Cidades from './pages/Cidades/Cidades';
import Combos from './pages/Combos/Combos';

const Main = styled.main`
  position: relative;
  overflow: auto;
  transition: all 0.15s;
  background-color: #f6f6f6;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
  height: calc(100vh - 56px - 57px);
 
  overflow-x: hidden;
`
const Head = styled.div`
  position: relative;
  overflow: auto;
  height: 56px;
`
const Bar = styled.div`
  position: relative;
  height: calc(100vh - 56px);
`
const Separator = styled.div`
  clear: both;
  position: relative;
  margin: 0.8rem;
  background-color: #f9dcdd;
  height: 1px;
`

const SideNavF = styled(SideNav)`
  background: #303443;
`

function App() {

  const urlApi = process.env.REACT_APP_API_URL;

  var sel = window.location.href.split("#/")[1]

  sel = sel === "" || !sel ? "home" : sel

  const [view, setView] = useState(sel)

  const [expanded, setState] = useState(false)

  return (
    <>
    {/* <img src={`${urlApi}/assets/carouselstv/foto_1712594965863.jpg`} alt="Carousel" /> */}
      <Head expanded={expanded}>
        <Navbar variant="dark" bg="dark" className="justify-content-between" sticky="top">
          <Image className="logo-cms" alt="logo" src={Logo} width="40" height="40" />
          <Navbar.Brand className="px-0">Sistema de Gerenciamento de Conteúdo</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="px-2">              
                <TitleAnim static texts={["Acesso rápido"]} /> 
                <i className="fa fa-fw fa-arrow-right" style={{ fontSize: "1.25em" }}></i>           
            </Navbar.Text>
          </Navbar.Collapse>
          <Form inline="true" className='form-bar'>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">Perfil</Tooltip>}
            >
              <Button
                variant="outline-warning"
                className="mr-sm-2"
                href="http://177.8.200.4/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-user" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">MK Solutions</Tooltip>}
            >
              <Button
                variant="outline-primary"
                className="mr-sm-2"
                href="http://177.8.200.4/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-tasks" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Sistem de Ordem de Serviço(OS)</Tooltip>}>
              <Button
                variant="outline-success"
                className="mr-sm-2"
                href="https://atendimento.gigabytetelecom.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-cogs" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>
          </Form>
        </Navbar>
      </Head>
      <Bar>
        <SideNavF
          expanded={expanded}
          onToggle={(expanded) => {
            setState(expanded)
          }}
          onSelect={(selected) => {
            const to = "#/" + selected
            if (view !== to) {
              setView(selected)
            }
          }}
        >
          <SideNav.Toggle />
          <SideNav.Nav selected={view} defaultSelected={sel}>
            <NavItem eventKey="home">
              <NavIcon>
                <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Página Inicial</NavText>
            </NavItem>
            
            <NavItem eventKey="internet">
              <NavIcon>
                <i className="fa fa-fw  fa-wifi" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Página Internet</NavText>
            </NavItem>

            <NavItem eventKey="phone">
              <NavIcon>
                <i className="fa fa-fw fa-phone-square" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Página Telefone</NavText>
            </NavItem>
            
            <NavItem eventKey="tv">
              <NavIcon>
                <i className="fa fa-fw fa-television" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Página TV</NavText>
            </NavItem>
            
            <NavItem eventKey="rural">
              <NavIcon>
                <i className="fa fa-fw fa-signal" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Página Internet Rural</NavText>
            </NavItem>

            <NavItem eventKey="videos">
              <NavIcon>
                <i className="fa fa-fw fa-video-camera" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Vídeos Explicativos</NavText>
            </NavItem>
            
            <NavItem eventKey="combos">
              <NavIcon>
                <i className="fa fa-fw fa-address-card" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Combos</NavText>
            </NavItem>

            <NavItem eventKey="cidades">
              <NavIcon>
                <i className="fa fa-fw fa-shopping-bag" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Lojas</NavText>
            </NavItem>

            <Separator />

            <NavItem eventKey="private">
              <NavIcon>
                <i className="fa fa-fw fa-user-secret" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Política de Privacidade</NavText>
            </NavItem>

            
            <NavItem eventKey="about">
              <NavIcon>
                <i className="fa fa-fw fa-info-circle" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Quem Somos</NavText>
            </NavItem>

            <NavItem eventKey="settings">
              <NavIcon>
                <i className="fa fa-fw fa-cogs" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Configurações</NavText>
            </NavItem>
          </SideNav.Nav>
        </SideNavF>
        <Main expanded={expanded}>
          {view === "home" && <Home />}
          {view === "internet" && <Internet />}
          {view === "phone" && <Phone />}
          {view === "card" && <CardSale />}
          {view === "offer" && <CardOffer />}
          {view === "tv" && <Tv />}
          {view === "rural" && <RuralInternet />}    
          {view === "private" && <Private />}
          {view === "combos" && <Combos />}
          {view === "about" && <About />}
          {view === "videos" && <Videos />}
          {view === "cidades" && <Cidades />}
          {view === "settings" && <Settings />}
        </Main>
        <Navbar variant="light" bg="light" className="justify-content-between border-top">
          <Navbar.Brand className="px-1"></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="footer">
            <img src={LogoGiga} alt='logo' />
            <Navbar.Text> © Gigabyte Telecom {new Date().getFullYear()}</Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </Bar>
    </>
  );
}

export default App;
