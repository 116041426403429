import React from "react";
import FadeInCard from "../../components/FadeInCard";
import './styles.css';
import CardRural from "../../components/HomeComponents/CardRural";

const RuralInternet = () => {
    return (
        <div className="container-fluid table">
        <div className="row content">
            <div className="col-sm-12">
                <h3 className="bem-vindo">
                    Editar Página Internet Rural
                </h3>
                <p className="bem-vindo-p">
                Bem-vindo ao Sistema de Gerenciamento de Conteúdo (CMS) do nosso site. 
                Este é o ponto de controle central para explorar e customizar todos os elementos essenciais 
                que compõem o nosso site. Nesta seção, você terá a capacidade de personalizar e administrar cada detalhe. 
                Abaixo, destacamos as opções específicas disponíveis para editar ou 
                cadastrar os principais componentes da nossa página inicial.
                </p>
                <p className="bem-vindo-p">
                Para personalizar as demais páginas do nosso site, acesse as opções disponíveis no menu lateral. 
                Este recurso intuitivo oferece acesso rápido e direto a 
                todas as áreas relevantes para a edição e atualização de conteúdo.
                </p>
            </div>
        </div>
        <div className="row">
                <div className="card-rural">
                    <h3><i className="fa fa-fw fa-wifi" />  Internet Rural</h3>
                    <span>Atenção para as dimensões das fotos para não distorcer o layout do site! Recomendação:  (1780x665 pixels)</span>
                </div>
                <div className="col-sm-12">
                    <br />
                    <CardRural />
                </div>
            </div>
        {/* <div className="row">
            <h3>Carousel/Slider</h3>
            <div className="col-sm-12">
                <CardRural />
            </div>
        </div> */}
    </div>
    )
}
export default RuralInternet;